var lang = $("html").attr("lang");
var cookiesLangs = {
  ca: {
    title: 'Aquest lloc web utilitza cookies',
    message: 'Utilitzem cookies pròpies i de tercers per millorar l’experiència d’usuari, analitzar el trànsit, la vostra navegació al web i personalitzar el contingut. Podeu “Acceptar totes les cookies”, “Configurar les cookies” que voleu acceptar o bé “Rebutjar-les totes” (excepte les cookies tècniques que són necessàries). Consulteu la nostra <a href="cookies_link" >política de cookies</a> per obtenir més informació.',
    link: '/ca/politica-de-cookies/',
    acceptBtnLabel: 'Accepta totes les cookies',
    rejectBtnLabel: 'Rebutjar-les totes',
    acceptBtnLabel2: 'Accepta les cookies',
    advancedBtnLabel: 'Configurar les cookies',
    moreInfoLabel: 'Més informació',
    cookieTypesTitle: 'Selecciona les galetes que vols acceptar',
    fixedCookieTypeLabel: 'Sessió',
    fixedCookieTypeDesc: 'Aquestes galetes són essencials perquè la web funcioni correctament.'
  },
  es: {
    title: 'Este sitio web utiliza cookies',
    message: 'Utilizamos cookies propias y de terceros para mejorar la experiencia del usuario, analizar el tráfico, su navegación en el sitio web y personalizar el contenido. Puede "Aceptar todas las cookies", "Configurar las cookies" que desea aceptar o "Rechazar todas" (excepto las cookies técnicas que son necesarias). Consulte nuestra <a href="cookies_link">política de cookies</a> para obtener más información.',
    link: '/es/politica-de-cookies/',
    acceptBtnLabel: 'Aceptar todas las cookies',
    rejectBtnLabel: 'Rechazar todas',
    acceptBtnLabel2: 'Aceptar las cookies',
    advancedBtnLabel: 'Configurar las cookies',
    moreInfoLabel: 'Más información',
    cookieTypesTitle: 'Selecciona las cookies que quieres aceptar',
    fixedCookieTypeLabel: 'Sesión',
    fixedCookieTypeDesc: 'Estas cookies són esenciales para que la web funcione correctamente.'
  },
  en: {
    title: 'This website uses cookies',
    message: 'We use our own and third-party cookies to improve the user experience, analyze traffic, your navigation on the website, and personalize content. You can "Accept all cookies," "Configure the cookies" you want to accept, or "Reject all" (except technical cookies which are necessary). Please refer to our <a href="cookies_link">cookie policy</a> for more information.',
    link: '/en/cookies-policy/',
    acceptBtnLabel: 'Accept all cookies',
    rejectBtnLabel: 'Reject all',
    acceptBtnLabel2: 'Accept cookies',
    advancedBtnLabel: 'Configure cookies',
    moreInfoLabel: 'More information',
    cookieTypesTitle: 'Select the cookies you want to accept',
    fixedCookieTypeLabel: 'Session',
    fixedCookieTypeDesc: 'These cookies are essential for the website to function properly.'
  }
}
var cookiesLangsMarketing = {
  ca: {
      message: 'Utilitzem cookies pròpies i de tercers per millorar l’experiència d’usuari, analitzar el trànsit, la vostra navegació al web i personalitzar el contingut. Podeu “Acceptar totes les cookies”, “Configurar les cookies” que voleu acceptar o bé “Rebutjar-les totes” (excepte les cookies tècniques que són necessàries). Consulteu la nostra <a href="cookies_link">política de cookies</a> per obtenir més informació.',
  },
  es: {
      message: 'Utilizamos cookies propias y de terceros para mejorar la experiencia del usuario, analizar el tráfico, su navegación en el sitio web y personalizar el contenido. Puede "Aceptar todas las cookies", "Configurar las cookies" que desea aceptar o "Rechazar todas" (excepto las cookies técnicas que son necesarias). Consulte <a href="cookies_link">nuestra política de cookies</a> para obtener más información.',
  },
  en: {
      message: 'We use our own and third-party cookies to improve the user experience, analyze traffic, your navigation on the website, and personalize content. You can "Accept all cookies," "Configure the cookies" you want to accept, or "Reject all" (except technical cookies which are necessary). Please refer to our <a href="cookies_link">cookie policy</a> for more information.',
  }
}
var cookiesTypes = {
  ca: {
    preferences: {
      type: 'Personalització',
      value: 'preferences',
      description: 'Es tracta de cookies relacionades amb les preferències del vostre lloc, per ex. recordant l\'idioma, nom d\'usuari, etc.'
    },
    analytics:{
      type: 'Anàlisi / Tercers',
      value: 'analytics',
      description: 'Cookies relacionades amb visites al lloc, tipus de navegadors, etc.'
    },
    marketing: {
      type: 'Màrqueting',
      value: 'marketing',
      description: 'Cookies relacionades amb el màrqueting, p. ex. butlletins, xarxes socials, etc.'
    }
  },
  es: {
    preferences: {
      type: 'Personalización',
      value: 'preferences',
      description: 'Estas son cookies que están relacionadas con las preferencias de su sitio, por ej. recordando el idioma, nombre de usuario, etc.'
    },
    analytics: {
      type: 'Análisis / Terceros',
      value: 'analytics',
      description: 'Cookies relacionadas con visitas al sitio, tipos de navegador, etc.'
    },
    marketing: {
      type: 'Marketing',
      value: 'marketing',
      description: 'Cookies relacionadas con el marketing, p. Ej. boletines, redes sociales, etc.'
    }
  },
  en: {
    preferences: {
      type: 'Personalization',
      value: 'preferences',
      description: 'These are cookies that are related to your site preferences, e.g. remembering language, username, etc.'
    },
    analytics: {
      type: 'Analysis / Third Party',
      value: 'analytics',
      description: 'Cookies related to site visits, browser types, etc.'
    },
    marketing: {
      type: 'Marketing',
      value: 'marketing',
      description: 'Cookies related to marketing, e.g. newsletters, social media, etc.'
    }
  }
}

function cookiesActivate() {
  if ($.fn.ihavecookies.preference('preferences') === true) {
    var date = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    document.cookie = "lang="+lang+"; expires="+date.toGMTString()+"; path=/";
  }
  if ($.fn.ihavecookies.preference('analytics') === true && typeof cookiesAnalytics === "function") {
    cookiesAnalytics();
  }
  if ($.fn.ihavecookies.preference('marketing') === true && typeof cookiesMarketing === "function") {
    cookiesMarketing();
  }
}


function deleteCookies (names) {

  // Obté totes les cookies de la plana
  var allCookies = document.cookie.split(';');
  for (var i = 0; i < allCookies.length; i++) {
    var cookie = allCookies[i];
    var cookieName = cookie.split('=')[0].trim();

    // Esborrar les cookies menys la passada
    if (names.some(function (name) { return cookieName.indexOf(name) === -1; }) && cookieName != "PHPSESSID") {

        // Establecer la cookie para que expire inmediatamente
      document.cookie = cookieName + '=; max-age=0; expires=-1; path=/;';
      document.cookie = cookieName + '=; max-age=0; expires=-1; path=/;domain=' + getDomainName(window.location.hostname);
      document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=localhost';
    }
  }
}

function getDomainName(hostName)
  {
      return '.' + hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
  }


var options = Object.assign(cookiesLangs[lang], {
  cookieTypes: [
    cookiesTypes[lang]['preferences']
  ],
  delay: 300,
  expires: 90,
  onAccept: function() {
    cookiesActivate();
  },
  uncheckBoxes: true,
});

if (typeof cookiesAnalytics === "function") {
  options.cookieTypes.push(cookiesTypes[lang]['analytics']);
}
if (typeof cookiesMarketing === "function") {
  options.cookieTypes.push(cookiesTypes[lang]['marketing']);
  options = Object.assign(options, cookiesLangsMarketing[lang]);
}
options.message = options.message.replace('cookies_link', options.link);
$(document).ready(function() {
    $('body').ihavecookies(options);
    cookiesActivate();
    $('.ihavecookies_change').click(function(event){
      event.preventDefault();
        $('body').ihavecookies(options, 'reinit');
      });
});
