//dimsemenov.com/plugins/magnific-popup/documentation.html#translating
https: $.extend(true, $.magnificPopup.defaults, {
  tClose: "Tancar (Esc)", // Alt text on close button
  tLoading: "Carregant imatge…", // Text that is displayed during loading. Can contain %curr% and %total% keys
  gallery: {
    tPrev: "Anterior (Tecla de fletxa esquerra)", // Alt text on left arrow
    tNext: "Següent (Tecla de fletxa dreta)", // Alt text on right arrow
    tCounter: "%curr% de %total%", // Markup for "1 of 7" counter
  },
  image: {
    tError: '<a href="%url%">La imatge</a> no s\'ha pogut carregar.', // Error message when image could not be loaded
    titleSrc: 'title',
  }
});
