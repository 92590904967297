$(document).ready(function () {
  ("use strict");

  // Menú: afegim botó i ocultem menú quan hi ha js
  $("#menu")
    .before(
      '<button class="hamburger hamburger--spring" type="button" aria-expanded="false" aria-controls="menu"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="is-sr-only">Menu</span></button>'
    )
    .attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  var toggleMenu = document.querySelector(".navigation button");
  var menu = document.querySelector("#menu");

  if (toggleMenu) {
    toggleMenu.addEventListener("click", function () {
      var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
      toggleMenu.setAttribute("aria-expanded", !open);
      menu.hidden = !menu.hidden;
    });
  }

  $(".hamburger").on("click", function () {
    // afegir classe al botó
    $(this).toggleClass("is-active");
    // afegir classe al body
    // $("body").toggleClass("js-menu-open");
  });

  // -------------------------------
  // Menú principal amb desplegables (https://www.w3.org/WAI/tutorials/menus/flyout/#use-button-as-toggle)
  // -------------------------------

  function hasClass(el, className) {
    return el.classList
      ? el.classList.contains(className)
      : new RegExp("(^| )" + className + "( |$)", "gi").test(el.className);
  }

  var menuItems1 = document.querySelectorAll("li.has-submenu");
  var timer1, timer2;

  var parseHTML = function (str) {
    var tmp = document.implementation.createHTMLDocument();
    tmp.body.innerHTML = str;
    return tmp.body.children;
  };

  Array.prototype.forEach.call(menuItems1, function (el, i) {
    var activatingA = el.querySelector("a");
    var btn =
      '<button type="button"><span class="is-sr-only">Mostra el submenú de “' +
      activatingA.text +
      "”</span></button>";
    activatingA.insertAdjacentHTML("afterend", btn);

    // Handle hover event for non-touch devices
    el.addEventListener("mouseover", function (event) {
      this.classList.add("open");
      this.querySelector("a").setAttribute("aria-expanded", "true");
      this.querySelector("button").setAttribute("aria-expanded", "true");
      clearTimeout(timer1);
    });

    el.addEventListener("mouseout", function (event) {
      timer1 = setTimeout(function () {
        var openMenu = document.querySelector(".has-submenu.open");
        if (openMenu) {
          openMenu.querySelector("a").setAttribute("aria-expanded", "false");
          openMenu
            .querySelector("button")
            .setAttribute("aria-expanded", "false");
          openMenu.classList.remove("open");
        }
      }, 500);
    });

    // Handle click and touchstart events for touch devices
    el.querySelector("button").addEventListener("click", function (event) {
      event.preventDefault();
      toggleSubmenu(el);
    });

    el.querySelector("button").addEventListener("touchstart", function (event) {
      event.preventDefault();
      toggleSubmenu(el);
    });

    var links = el.querySelectorAll("a");
    Array.prototype.forEach.call(links, function (link, i) {
      link.addEventListener("focus", function () {
        if (timer2) {
          clearTimeout(timer2);
          timer2 = null;
        }
      });
      link.addEventListener("blur", function (event) {
        timer2 = setTimeout(function () {
          var openNav = document.querySelector(".has-submenu.open");
          if (openNav) {
            openNav.className = "has-submenu";
            openNav.querySelector("a").setAttribute("aria-expanded", "false");
            openNav
              .querySelector("button")
              .setAttribute("aria-expanded", "false");
          }
        }, 10);
      });
    });
  });

  function toggleSubmenu(el) {
    var isOpen = hasClass(el, "open");

    // Close any other open submenus
    var allMenus = document.querySelectorAll("li.has-submenu");
    Array.prototype.forEach.call(allMenus, function (item) {
      item.classList.remove("open");
      item.querySelector("a").setAttribute("aria-expanded", "false");
      item.querySelector("button").setAttribute("aria-expanded", "false");
    });

    // Open the clicked/touched submenu if it was not already open
    if (!isOpen) {
      el.classList.add("open");
      el.querySelector("a").setAttribute("aria-expanded", "true");
      el.querySelector("button").setAttribute("aria-expanded", "true");
    }
  }

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function (event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function () {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // --------
  // Cercador
  // --------
  document
    .querySelector('[data-a11y-toggle="search"]')
    .addEventListener("click", function () {
      setTimeout(function () {
        document.getElementById("s").focus();
      }, 0);
    });

  // ----------
  // scroll top
  // ----------
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 400) {
      $(".scrolltop").addClass("visible");
    } else {
      $(".scrolltop").removeClass("visible");
    }
  });

  // ------
  // Slider
  // ------
  // Traducció https://github.com/Accessible360/accessible-slick/issues/34#issuecomment-875617370
  $(".slider").on("init", function (e, slick) {
    var lang = $("html").attr("lang");

    if ($(slick.$dots).length && (lang === "ca" || lang === "es")) {
      var replacementText =
        lang === "ca" ? "Anar a la diapositiva" : "Ir a la diapositiva";

      slick.$dots[0]
        .querySelectorAll("button .slick-sr-only")
        .forEach(function (buttonText) {
          buttonText.innerText = buttonText.innerText.replace(
            "Go to slide",
            replacementText
          );
        });
    }

    // Traiem atributs innecessaris quan només hi ha una foto i no té funcionament de slider
    // if (slick.slideCount === 1) {
    //   var $slider = $(slick.$slider);
    //   var $slide = $slider.find(".slick-slide");

    //   $slider.removeAttr("role aria-label");

    //   $slide.removeAttr("data-slick-index role aria-label");
    // }
  });

  $(".slider").slick({
    dots: true,
    arrows: true,
    // responsive: [
    //   {
    //     breakpoint: 769,
    //     settings: {
    //       arrows: false,
    //     },
    //   },
    // ],
  });

  // --------------
  // Slider galeria
  // --------------
  // $('.gallery').on('init', function(e, slick) {
  //   var lang = $('html').attr('lang');

  //   if (lang === 'ca' || lang === 'es') {
  //     var prevText = lang === 'ca' ? 'Anterior' : 'Anterior';
  //     var nextText = lang === 'ca' ? 'Següent' : 'Siguiente';

  //     $('.slick-prev .slick-sr-only').each(function() {
  //       $(this).text($(this).text().replace('Previous', prevText));
  //     });

  //     $('.slick-next .slick-sr-only').each(function() {
  //       $(this).text($(this).text().replace('Next', nextText));
  //     });
  //   }
  // });
  // $('.gallery').slick({
  //   dots: false,
  //   arrows: true,
  //   centerPadding: $('.gallery img').width()/2 + 'px',
  //   centerMode: true,
  //   slidesToShow: 2,
  //   responsive: [
  //     {
  //       breakpoint: 1400,
  //       settings: {
  //         slidesToShow: 2,
  //         centerPadding: '100px',
  //       }
  //     },
  //     {
  //       breakpoint: 1100,
  //       settings: {
  //         slidesToShow: 1,
  //         centerPadding: '200px',
  //       }
  //     },
  //     {
  //       breakpoint: 800,
  //       settings: {
  //         slidesToShow: 1,
  //         centerPadding: '80px',
  //       }
  //     }
  //   ]
  // });

  // var updateLiveRegion = function (event, slick, currentSlide) {
  //   var slideIndex = currentSlide + 1;
  //   var slideTotal = slick.slideCount;
  //   var currentSlideContent =
  //     $(slick.$slides[currentSlide]).find("img").attr("alt") ||
  //     "slide " + slideIndex;

  //   // Update the live region with the slide index and content description
  //   $("#carousel-status").text(
  //     "Foto " + slideIndex + " de " + slideTotal + ": " + currentSlideContent
  //   );
  // };

  // Initialize the carousel
  // $(".gallery").slick({
  //   dots: false,
  //   arrows: true,
  //   centerPadding: "0px",
  //   centerMode: true,
  //   slidesToShow: 2,
  //   responsive: [
  //     {
  //       breakpoint: 1400,
  //       settings: {
  //         slidesToShow: 2,
  //         centerPadding: "100px",
  //       },
  //     },
  //     {
  //       breakpoint: 1100,
  //       settings: {
  //         slidesToShow: 1,
  //         centerPadding: "200px",
  //       },
  //     },
  //     {
  //       breakpoint: 800,
  //       settings: {
  //         slidesToShow: 1,
  //         centerPadding: "80px",
  //       },
  //     },
  //   ],
  // });

  // Add the init event for language setup and live region updates
  // $(".gallery").on("init", function (e, slick) {
  //   var lang = $("html").attr("lang");

  //   if (lang === "ca" || lang === "es") {
  //     var prevText = lang === "ca" ? "Anterior" : "Anterior";
  //     var nextText = lang === "ca" ? "Següent" : "Siguiente";

  //     $(".slick-prev .slick-sr-only").each(function () {
  //       $(this).text($(this).text().replace("Previous", prevText));
  //     });

  //     $(".slick-next .slick-sr-only").each(function () {
  //       $(this).text($(this).text().replace("Next", nextText));
  //     });
  //   }

  //   // Set the initial slide announcement
  //   updateLiveRegion(e, slick, slick.currentSlide);
  // });

  // Add an afterChange event to update the live region when slides change
  // $(".gallery").on("afterChange", updateLiveRegion);

  
  // -----------
  // File upload
  // -----------
  $('input[type="file"]').on('change', function () {
    var file = $(this)[0].files[0].name;
    $(this).closest('.file').find(".file-name").text(file);
  });

  // ----
  // Tabs https://inclusive-components.design/tabbed-interfaces/
  // ----
  // if ($(".tabbed").length > 0) {
  //   $(".tabbed").each(function (index, element) {
  //     // Get relevant elements and collections
  //     var tabbed = element;
  //     var tablist = tabbed.querySelector("ul.tab-menu");
  //     var tabs = tablist.querySelectorAll("a");
  //     var panels = tabbed.querySelectorAll(".tab-panel");

  //     // The tab switching function
  //     var switchTab = function switchTab(oldTab, newTab) {
  //       newTab.focus(); // Make the active tab focusable by the user (Tab key)

  //       newTab.removeAttribute("tabindex"); // Set the selected state

  //       newTab.setAttribute("aria-selected", "true");
  //       oldTab.removeAttribute("aria-selected");
  //       oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
  //       // tab panels to show and hide

  //       var index = Array.prototype.indexOf.call(tabs, newTab);
  //       var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
  //       panels[oldIndex].hidden = true;
  //       panels[index].hidden = false;
  //     }; // Add the tablist role to the first <ul> in the .tabbed container

  //     tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

  //     Array.prototype.forEach.call(tabs, function (tab, i) {
  //       tab.setAttribute("role", "tab");
  //       //	    tab.setAttribute("id", "tab" + (i + 1));
  //       tab.setAttribute("tabindex", "-1");
  //       tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

  //       tab.addEventListener("click", function (e) {
  //         e.preventDefault();
  //         var currentTab = tablist.querySelector("[aria-selected]");

  //         if (e.currentTarget !== currentTab) {
  //           switchTab(currentTab, e.currentTarget);
  //         }
  //       }); // Handle keydown events for keyboard users

  //       tab.addEventListener("keydown", function (e) {
  //         // Get the index of the current tab in the tabs node list
  //         var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
  //         // Calculate the new tab's index where appropriate

  //         var dir =
  //           e.which === 37
  //             ? index - 1
  //             : e.which === 39
  //             ? index + 1
  //             : e.which === 40
  //             ? "down"
  //             : null;

  //         if (dir !== null) {
  //           e.preventDefault(); // If the down key is pressed, move focus to the open panel,
  //           // otherwise switch to the adjacent tab

  //           dir === "down"
  //             ? panels[i].focus()
  //             : tabs[dir]
  //             ? switchTab(e.currentTarget, tabs[dir])
  //             : void 0;
  //         }
  //       });
  //     }); // Add tab panel semantics and hide them all

  //     Array.prototype.forEach.call(panels, function (panel, i) {
  //       panel.setAttribute("role", "tabpanel");
  //       panel.setAttribute("tabindex", "-1");
  //       var id = panel.getAttribute("id");
  //       panel.setAttribute("aria-labelledby", tabs[i].id);
  //       panel.hidden = true;
  //     }); // Initially activate the first tab and reveal the first tab panel

  //     tabs[0].removeAttribute("tabindex");
  //     tabs[0].setAttribute("aria-selected", "true");
  //     panels[0].hidden = false;
  //   });
  // }
});

// -----------
// Collapsible (https://inclusive-components.design/collapsible-sections/)
// -----------
// (function () {
//   // Get all the headings
//   const headings = document.querySelectorAll(".collapsible__title");

//   Array.prototype.forEach.call(headings, (heading) => {
//     // Give each <h2> a toggle button child
//     // with the SVG plus/minus icon
//     heading.innerHTML = `
//       <button aria-expanded="false" class="is-flex is-justify-content-space-between is-align-items-center py-4">
//         ${heading.textContent}
//         <svg class="is-flex-shrink-0" aria-hidden="true" focusable="false" width="30" height="16"><path d="m29.002 1.002-14 14-14.005-14" stroke="#000" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>
//       </button>
//     `;
//     // Function to create a node list
//     // of the content between this heading and the next
//     const getContent = (elem) => {
//       let elems = [];
//       while (
//         elem.nextElementSibling &&
//         elem.nextElementSibling.className !== "collapsible__title"
//       ) {
//         elems.push(elem.nextElementSibling);
//         elem = elem.nextElementSibling;
//       }

//       // Delete the old versions of the content nodes
//       elems.forEach((node) => {
//         node.parentNode.removeChild(node);
//       });

//       return elems;
//     };

//     // Assign the contents to be expanded/collapsed (array)
//     let contents = getContent(heading);

//     // Create a wrapper element for `contents` and hide it
//     let wrapper = document.createElement("div");
//     wrapper.hidden = true;

//     // Add each element of `contents` to `wrapper`
//     contents.forEach((node) => {
//       wrapper.appendChild(node);
//     });

//     // Add the wrapped content back into the DOM
//     // after the heading
//     heading.parentNode.insertBefore(wrapper, heading.nextElementSibling);

//     // Assign the button
//     let btn = heading.querySelector("button");

//     btn.onclick = () => {
//       // Cast the state as a boolean
//       let expanded = btn.getAttribute("aria-expanded") === "true" || false;

//       // Switch the state
//       btn.setAttribute("aria-expanded", !expanded);
//       // Switch the content's visibility
//       wrapper.hidden = expanded;
//     };
//   });

//   if (
//     $(".collapsible__title").length > 0 &&
//     window.location.hash.indexOf("#entry-") > -1 &&
//     $(window.location.hash).length > 0
//   ) {
//     $(window.location.hash).find("button").attr("aria-expanded", true);
//     $(window.location.hash).next().show();
//     $("html, body").animate(
//       {
//         scrollTop: $(window.location.hash).parent().offset().top,
//       },
//       1000
//     );
//   }
// })();

// Accessible card links https://codepen.io/joelstrohmeier/pen/bGEMpmz
// function linkBoxes() {
//   var boxes = document.querySelectorAll("div.banner");

//   boxes.forEach(function (box) {
//     var link = box.querySelector("a");
//     if (link) {
//       var url = link.getAttribute("href");
//       box.addEventListener("click", function () {
//         location.href = url;
//         link.preventDefault;
//       });
//       box.classList.add("linkify");
//       link.addEventListener("focus", function () {
//         box.classList.add("isfocused");
//       });
//       link.addEventListener("blur", function () {
//         box.classList.remove("isfocused");
//       });

//       /* Inject CTA in to cards as visual affordance but hide from assistive tech announcements */
//       // box.insertAdjacentHTML("beforeend", '<span class="cta" aria-hidden="true">Read more</span>');
//     }
//   });
// }
// document.addEventListener("DOMContentLoaded", function () {
//   if ("querySelector" in document) {
//     linkBoxes();
//   }
// });

document.addEventListener("DOMContentLoaded", () => {
  const startDateInput = document.getElementById("inici");
  const endDateInput = document.getElementById("fi");
  if (startDateInput && endDateInput) {
    // Quan es modifica la data d'inici
    startDateInput.addEventListener("change", () => {
      const startDate = new Date(startDateInput.value);
      const endDate = new Date(endDateInput.value);

      // Si la data d'inici supera la de final, ajustem la de final
      if (startDate && endDate && startDate > endDate) {
        endDateInput.value = startDateInput.value;
      }

      // Ajustem el valor mínim per la data de final
      endDateInput.min = startDateInput.value;
    });

    // Quan es modifica la data de final
    endDateInput.addEventListener("change", () => {
      const startDate = new Date(startDateInput.value);
      const endDate = new Date(endDateInput.value);

      // Si la data de final és anterior a la d'inici, ajustem la d'inici
      if (startDate && endDate && endDate < startDate) {
        startDateInput.value = endDateInput.value;
      }

      // Ajustem el valor màxim per la data d'inici
      startDateInput.max = endDateInput.value;
    });
  }
});
